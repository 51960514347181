































export default
  name: 'about'
  computed:
    registration_open: -> @$store.state.registration_open
